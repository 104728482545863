import { Component } from "react";
import React from'react';

class App extends Component {
  //  async getData() {
  //    const res = await fetch("./HtmlPage1.html");
  //    const data = await res.text();
  //    console.log(data);
  //    return this.setState({ data });
  //  }
 
  //  componentDidMount() {
  //    this.getData();
  //  }
 
   render() {
    //  let template = { __html: this.state.data };
     return (
       <div className="App">
         <header className="App-header">
            {/* <div dangerouslySetInnerHTML={template}></div> */}
           {/* <img src={logo} className="App-logo" alt="logo" />
           <div>{this.state.data}</div> */}
         </header>
       </div>
     );
   }
 }

 export default App;