import React from 'react';
import './resumeStyle.css';
// import { useState } from 'react';

// import veeruPhoto from './images/LinkedIn-Pic.jpg';

export default function Resume() {
  //const [isHovering, setIsHovering] = useState(false);

//   const handleMouseOver = () => {
//     setIsHovering(true);
//   };

    return (
        <html>
        <body>        
        {/* <div className="header">
            <a href="#about" 
                onMouseOver={handleMouseOver}
                // onMouseOut={handleMouseOut} 
            >Products</a>
        </div> */}

        {/* {isHovering &&  */}
        <div className="resume-container">
        <div className="left-display">
            <div className="profile-image-container">                
                {/* <img src={veeruPhoto} className="profile-image" alt="Veeranjaneyulu.J" width="200"></img> */}
            </div>
            <div className="profile-name">
                <br />
                <p>
                    Name: Veeranjaneyulu Jakka
                    <br />e-Mail: veerujakaka@gmail.com
                    <br />Phone No: +91 9959073151
                    <br />Place: Hyderabad
                    <br />Nationality: Indian
                </p>
            </div>
            <div className="left-heading">
                Education
            </div>
            <div className="left-content">
                M.Tech (CSE) from JNTU, Kukatpally, Hyderabad, India, 2008-2011.                   
                <br /><br /> B.Tech (CSE) from Sir CR Reddy College of Engineering, Eluru, Andhra University, India, 2001-2005.                    
            </div>
            
            <div className="left-heading">
                Technical Areas of Exposure
            </div>
            
            <div className="left-content">
                <br />17+ Yrs in Oracle SQL, PLSQL
                <br />10+ Yrs in ASP.Net, C# Web & Forms applications
                <br />6+ Yrs in Crystal Reports 
                <br />2+ Yrs in React
                <br />2+ Yrs in Angular
                <br />2+ Yrs in Node.JS
                <br />2+ Yrs in MongoDB
                <br />2+ Yrs in Arduino UNO
                <br />2+ Yrs in MERN Stack
                <br />2+ Yrs in MEAN Stack
                <br />1+ Yrs in PHP
                <br />Azure-Fundamentals & Administrator Associate
            </div>
        </div>
        <div className='right-display'>
            <div className="right-content">
                <ul>
                    <li>Over 17+ years of experience in IFS 2004 ERP application in various modules like Payrolls, Manufacturing, IMM, Projects & Admin.</li>
                    <li>Experience in requirement analysis, design and development distributed web applications using C#.Net, HTML, JavaScript and CSS. </li>
                    <li>Possesses good knowledge and experience in Oracle 10g,11g SQL & PL/SQL database systems & Microsoft C#.Net for web development and Windows Forms applications. </li>
                    <li>Development of various Industry 4.0 solutions using Aduino UNO programming for monitoring of Temperature and humidity monitoring and controlling the equipment.</li>
                    <li>Development of reports using Crystal Reports</li>
                </ul>
            </div>
            <div className="right-heading">
                Career Profile:
            </div>
            <div className="right-content">
                <ul>
                    <li>
                        Senior Manager(IT), Hindustan Aeronautics Limited, Hyderabad.
                        Period: June-2006 to Till date.
                    </li>
                    <li>
                        Lecturer at Regency Institute of Technology, Yanam, Andhra Pradesh.
                        Period: November-2005 to June-2006.
                    </li>
                </ul>
            </div>

            <div className="right-heading">
                Functions Areas of Exposure
            </div>
            <div className="right-content">
                <ul>
                    <li>
                        17+ Yrs Experience in IFS 2004 in various modules liike IMM, Manufacturring, MRO, Payrolls, Projects & Income Tax
                    </li>
                </ul>
            </div>
            
            <div className="right-heading">
                Project Experience
            </div>
            <div className="right-content">
                <ul>
                    <li>
                        Dashboard Design & Development
                        Period: Jan-2018 to till date
                    </li>
                    <li>
                        Industry 4.0
                        Period: Jul-2020 to till date
                    </li>
                    <li>
                        Booking & Tracking Systems
                        Period: Jul-2015 to till date
                    </li>
                    <li>
                        Security Applications
                        Period: Jul-2015 to till date
                    </li>

                    <li>
                        IFS IMM, Outsourcing & Projects
                        Period: Jul-2015 to till date
                    </li>
                    
                    <li>
                        IFS Manufacturing & MRO
                        Period: Jan-2013 to Jul-2015
                    </li>

                    <li>
                        IFS Payrolls, Income Tax & Incentive
                        Period: Jun-2006 to Dec-2012
                    </li>
                </ul>
            </div>
        </div>
   </div>
        {/* } */}
        </body>
        </html>
        
        )
}
