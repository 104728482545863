import React from 'react';
import './MegaMenu.css';
import { useState, useEffect } from 'react';
//import { Transition , CSSTransition, TransitionGroup, } from 'react-transition-group';

import chart from './images/ChartDeveloper.jpg';
import crosstable from './images/CrossTableMaker.jpg';
// import reportmaker from './images/ReportMaker.jpg';
// import gridmaker from './images/GridMaker.jpg';
import projectmaker from './images/ProjectMaker.jpg';
// import formmaker from './images/FormMaker.jpg';
// import veeruResume from './components/Veeru-Resume';
// import ScriptTag from 'react-script-tag';
// import {Helmet} from "react-helmet";
import { useRef } from 'react';
import VeeruResume from './VeeruResume';
import ChartMaker from './ChartMaker';
import ProductCards from './ProductCards';
import GridMenu from './GridMenu';

export default function Exercises() {
    const menuRef = useRef(null);
    const productRef = useRef(null);
    const girdRef = useRef(null);

    const [isResumeHovering, setIsResumeHovering] = useState(false);
    const [isChartsHovering, setIsChartsHovering] = useState(false);
    const [isProductsHovering, setIsProdcuctsHovering] = useState(false);

    useEffect(() => {
        //handleMouseProducts();
    });

    const handleMouseOver = () => {
        hideAll();
        menuRef.current.style.height = "520px";
    };
    const handleMouseResume = () => {
        hideAll();
        setIsResumeHovering(true);
    };
    const handleMouseCharts = () => {
        hideAll();
        setIsChartsHovering(true);
    };

    const handleMouseProducts = () => {
        hideAll();
        setIsProdcuctsHovering(true);
        productRef.current.style.height = "520px";
    }

    const handleGridMenu = () =>{
        hideAll();
        girdRef.current.style.height = "520px";
    }

    const hideAll = () =>{
        menuRef.current.style.height = "0px";
        productRef.current.style.height = "0px";
        girdRef.current.style.height = "0px";

        setIsResumeHovering(false);
        setIsChartsHovering(false);
        setIsProdcuctsHovering(false);
    }
    return (
    <div>
        <div class="header">
          <a href="#default" class="logo">Tools</a>
          <div class="header-right">
            {/* <a href="#chartTypes" 
               onClick={handleMouseCharts}
                >Chart Types</a> */}

            <a href="#chartTypes" 
                   onClick={handleMouseProducts}
                    >Product Bucket</a>

            {/* <a href="#contact">Contact</a> */}

            <a href="#about" 
                onClick={handleMouseOver}
                >Products</a>

            <a href="#about" 
                onClick={handleGridMenu}
                >Grid Menu</a>
            {/* <a href="#resume" 
                onClick={handleMouseResume}
                >Visual Resume</a> */}
          </div>
        </div>
        {
        (
            <div id="dropdown" className="dropdown" ref={menuRef}>
            <div id = "dropdown-content" className="dropdown-content">
                <div class="column-total">
                    <div className="column">
                        <img src={chart} alt="Chart Developer" ></img>
                        
                        <button onClick={handleMouseCharts}>Chart Developer</button>
                    </div>

                    <div className="column">
                        <img src={crosstable} alt="Cross table Maker" ></img>
                        <button>Cross table Maker</button>
                    </div>


                    <div className="column-height">
                        <img src={projectmaker} alt="Project Maker" ></img>
                        <button>Project Maker</button>
                    </div>

                    <div className="column-wide"> 
                                Analytics & Visualization
                    </div>
                </div>                
            </div>
            </div>
        )}
        {
            isResumeHovering&&(
                <VeeruResume />
            )
        }
        {
            isChartsHovering&&(
                <ChartMaker />
            )
        }
        {
            <div id="dropdown" className="defaultDropdown" ref={productRef}>
            {/* isProductsHovering&&( */}
                    <ProductCards />
            {/* ) */}
            </div>
        }

        {
            <div id="griddropdown" className="dropdown" ref={girdRef}>
            {/* isProductsHovering&&( */}
                    <GridMenu />
            {/* ) */}
            </div>
        }
    </div>        
    )
}