import React from 'react';
import './ProductCards.css';
import { useState } from 'react';

import MERNStack from './images/MERN-Home.jpg';
import MEANStack from './images/MEAN-HomPage.png';

import ChartDeveloper from './images/ChartDeveloper.jpg';
import GridMaker from './images/GridMaker.jpg';
import FormMaker from './images/FormMaker.jpg';
import ReportMaker from './images/ReportMaker.jpg';
import ProjectMaker from './images/ProjectMaker.jpg';

import DiagramMaker from './images/DiagramMaker.jpg';
import CrossTableMaker from './images/CrossTableMaker.jpg';
import CSSFileGenerator from './images/CSS-File-Generator.jpg';
import ImageMapping from './images/ImageMapping.jpg';
import SlideMaker from './images/SlideMaker.jpg';

export default function Exercises() {
    const [isMern, setIsMern] = useState(true);
    const [isMean, setIsMean] = useState(false);
    const [isChart, setIsChart] = useState(false);
    const [isGrid, setIsGrid] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [isForm, setIsForm] = useState(false);
    const [isProject, setIsProject] = useState(false);
    const [isDiagram, setIsDiagram] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [isCrossTable, setIsCrossTable] = useState(false);
    const [isCSSFile, setIsCSSFile] = useState(false);
    
    const hideAll = () => {
        setIsMern(false);
        setIsMean(false);
        setIsChart(false);
        setIsGrid(false);
        setIsReport(false);
        setIsForm(false);
        setIsProject(false);
        setIsDiagram(false);
        setIsImage(false);
        setIsSlide(false);
        setIsCrossTable(false);
        setIsCSSFile(false);
    }
    const handleMern = () => {
          hideAll();
          setIsMern(true);
    };
    const handleMean = () => {
          hideAll();
          setIsMean(true);
    };
    const handleChart = () => {
          hideAll();
          setIsChart(true);
    };
   const handleGrid = () => {
         hideAll();
         setIsGrid(true);
   };
   const handleReport = () => {
         hideAll();
         setIsReport(true);
   };
   const handleForm = () => {
         hideAll();
         setIsForm(true);
   };
   const handleProject = () => {
         hideAll();
         setIsProject(true);
   };
   const handleDiagram = () => {
         hideAll();
         setIsDiagram(true);
   };
   const handleImage = () => {
         hideAll();
         setIsImage(true);
   };
   const handleSlide = () => {
         hideAll();
         setIsSlide(true);
   };

    const handleCross = () => {
        hideAll();
        setIsCrossTable(true);
    };
    const handleCSSFile = () => {
        hideAll();
        setIsCSSFile(true);
    };
     return (   
 <div>     
         <div className="products-total-content">
             <div className="products-left-products">
                 <a href="#mern" onMouseOver={handleMern}  className={`${isMern ? "active" : ""}`} >MERN Stack</a>
                 <a href="#mean"  onMouseOver={handleMean} className={`${isMean ? "active" : ""}`} >MEAN Stack</a>
                 <a href="#charts" onMouseOver={handleChart} className={`${isChart ? "active" : ""}`} >Chart Developer</a>
                 <a href="#grid" onMouseOver={handleGrid} className={`${isGrid ? "active" : ""}`}>Grid Maker</a>
                 <a href="#report" onMouseOver={handleReport} className={`${isReport ? "active" : ""}`}>Report Maker</a>
                 <a href="#form" onMouseOver={handleForm} className={`${isForm ? "active" : ""}`}>Form Maker</a>
                 <a href="#project" onMouseOver={handleProject} className={`${isProject ? "active" : ""}`}>Project Maker</a>
                 <a href="#diagram" onMouseOver={handleDiagram} className={`${isDiagram ? "active" : ""}`}>Diagram Maker</a>
                 <a href="#image" onMouseOver={handleImage} className={`${isImage ? "active" : ""}`}>Image Mapping</a>
                 <a href="#slide" onMouseOver={handleSlide} className={`${isSlide ? "active" : ""}`}>Slide Maker</a>
                 <a href="#cross" onMouseOver={handleCross} className={`${isCrossTable ? "active" : ""}`}>Cross Table Maker</a>
                 <a href="#cssfile" onMouseOver={handleCSSFile} className={`${isCSSFile ? "active" : ""}`}>CSS File Generator</a>
             </div>
 
             {isMern&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>MERN Stack Automation</h3>
                        <div>MERN Stack Automation tool is to generate the code required for the application using MongoDB, Express JS, React JS and Node JS.</div>
                     </div>
                     <div className="product-card-image">
                         <img src={MERNStack} alt="MERN Stack Automation" ></img>
                     </div>
                 </div> 
             </div>
             )}

            {isMean&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>MEAN Stack Automation</h3>
                        <div>MEAN Stack Automation tool is to generate the code required for the application using MongoDB, Express JS, Angular JS and Node JS.
                        </div>
                     </div>
                     <div className="product-card-image">
                         <img src={MEANStack} alt="MEAN Stack Automation" ></img>
                     </div>
                 </div> 
             </div>
             )}

             
            {isChart&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>Chart Developer</h3>
                        <div>Chart Developer using JavaScript at browser level uses client browser resources instead of server side resources.

Runs in all modern browsers using HTML 5.0, CSS and JavaScript</div>
                     </div>
                     <div className="product-card-image">
                         <img src={ChartDeveloper} alt="Chart Developer" ></img>
                     </div>
                 </div> 
             </div>
             )}

            {isGrid&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>Grid Maker</h3>
                        <div>Grid Maker using JavaScript tool is to generate Editable Grid. It can be easily embedded into other applications with low coding/no coding by user. It works well with on-premise, distributed and cloud computing environments.                            
                        </div>
                     </div>
                     <div className="product-card-image">
                         <img src={GridMaker} alt="Grid Maker" ></img>
                     </div>
                 </div> 
             </div>
             )}

            {isReport&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>Report Maker</h3>
                        <div>
                        Report Making using JavaScript tool is to generate reports. It is best suited for on-premise as well as cloud environment and report generation using the client processing power without any high-end servers. Easy to deploy and use without any coding skills.
                        </div>
                     </div>
                     <div className="product-card-image">
                         <img src={ReportMaker} alt="Report Maker" ></img>
                     </div>
                 </div> 
             </div>
             )}
             
            {isForm&&(
             <div className="products-right-product">             
                 <div className="product-card">
                     <div className="product-card-content">
                        <h3>Form Maker</h3>
                        <div>
                        Form Maker using JavaScript tool is to design simple to complex forms in an efficient way. It reduces the time taken to design forms by simple drag and drop of fields. Automatic generation of data entered/edited/ deleted by submitting the form.
                        </div>
                     </div>
                     <div className="product-card-image">
                         <img src={FormMaker} alt="Form Maker" ></img>
                     </div>
                 </div> 
             </div>
             )}
             
             {isProject&&(
              <div className="products-right-product">             
                  <div className="product-card">
                      <div className="product-card-content">
                         <h3>Project Maker</h3>
                         <div>
                         Prepare project activities, timelines and associated resource mapping easily at browser level and distributed as an HTML file.
                         </div>
                      </div>
                      <div className="product-card-image">
                          <img src={ProjectMaker} alt="Project Maker" ></img>
                      </div>
                  </div> 
              </div>
              )}
             
              {isDiagram&&(
               <div className="products-right-product">             
                   <div className="product-card">
                       <div className="product-card-content">
                          <h3>Diagram Maker</h3>
                          <div>
                          User can prepare shapes and graphs which can be distributed easily as an HTML file.
                          </div>
                       </div>
                       <div className="product-card-image">
                           <img src={DiagramMaker} alt="Diagram Maker" ></img>
                       </div>
                   </div> 
               </div>
               )}
             
               {isImage&&(
                <div className="products-right-product">             
                    <div className="product-card">
                        <div className="product-card-content">
                           <h3>Image Mapping</h3>
                           <div>
                           Prepare an image mapping at browser level and distributed as an HTML file.
                           </div>
                        </div>
                        <div className="product-card-image">
                            <img src={ImageMapping} alt="Image Mapping"></img>
                        </div>
                    </div> 
                </div>
                )}
             
             
                {isSlide&&(
                   <div className="products-right-product">             
                       <div className="product-card">
                           <div className="product-card-content">
                              <h3>Slide Maker</h3>
                              <div>
                              Generate or prepare a Presentation at browser level and can be distributed easily as an HTML file.
                              </div>
                           </div>
                           <div className="product-card-image">
                               <img src={SlideMaker} alt="Slide Maker" ></img>
                           </div>
                       </div> 
                   </div>
                   )}

                {isCrossTable&&(
                 <div className="products-right-product">             
                     <div className="product-card">
                         <div className="product-card-content">
                            <h3>Cross Table Maker</h3>
                            <div>
                            Cross Table Maker using JavaScript tool is to generate cross table reports. User can make summary of data by drag & drop and can visualize the summary by charts coming with this tool. It can be easily embed into other applications with no coding or low coding by user.
                            </div>
                         </div>
                         <div className="product-card-image">
                             <img src={CrossTableMaker} alt="Cross Table Maker" ></img>
                         </div>
                     </div> 
                 </div>
                 )}
             
                 {isCSSFile&&(
                  <div className="products-right-product">             
                      <div className="product-card">
                          <div className="product-card-content">
                             <h3>CSS File Generator</h3>
                             <div>
                             Generate CSS files by creating Classes and assign properties for each class by using the user interface at browser level. No coding or low coding by user.</div>
                          </div>
                          <div className="product-card-image">
                              <img src={CSSFileGenerator} alt="CSS File Generator" ></img>
                          </div>
                      </div> 
                  </div>
                  )}
         </div>
     </div>
         )
 }