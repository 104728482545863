import React from 'react';
import './ChartMaker.css';
import { useState } from 'react';
// import { Component } from "react";
// import { BrowserRouter } from 'react-router-dom';


import D3BarChart1 from './chartImages/3DBarChart.jpg';
import D3ConeBarChart from './chartImages/3DConeBarChart.jpg';
import D3DoughnutChart from './chartImages/3DDoughnutChart.jpg';
import D3PieChart from './chartImages/3DPieChart.jpg';
import D3PyramidBarChart from './chartImages/3DPyramidBarChart.jpg';
import D3ColumnChart from './chartImages/3D-ColumnChart.jpg';
import D3ConeChart from './chartImages/3D-ConeChart.jpg';
import D3CylinderChart from './chartImages/3D-CylinderChart.jpg';
import D3PyramidChart from './chartImages/3D-PyramidChart.jpg';
import Stacked100Chart from './chartImages/100StackedChart.jpg';
import Stacked100ConnectedChart from './chartImages/100StackedConnectedChart.jpg';
import AreaChart from './chartImages/AreaChart.jpg';
import BarChart from './chartImages/BarChart.jpg';
import BubbbleChart from './chartImages/BubbleChart.jpg';
import DashboardDesign1 from './chartImages/ChartDeveloper-DashboardDesign.jpg';
// import DashboardDesign2 from './chartImages/ChartDeveloper-DashboardDesign2.png';
import ChordDiagram from './chartImages/ChordDiagram.jpg';
import CircularColumnChart from './chartImages/CircularColumnChart.jpg';
import ColumnChart from './chartImages/ColumnChart.jpg';
import CurveChart from './chartImages/CurveChart.jpg';
import DoughnutChart from './chartImages/DoughnutChart.jpg';
import EquilibriamChart from './chartImages/EquilibriumChart.jpg';
import FlowDiagram from './chartImages/FlowDiagram.jpg';
import HierarchicalChart from './chartImages/HierarchicalChart.jpg';
import HorizontalWingChart from './chartImages/HorizontalWingChart.jpg';
import InvertedPyramidChart from './chartImages/InvertedPyramidChart.jpg';
import Linechart from './chartImages/LineChart.jpg';
import LollipopChart from './chartImages/LollipopChart.jpg';
import PieChart from './chartImages/PieChart.jpg';
import PyramidChart from './chartImages/PyramidChart.jpg';
import RadarChart2 from './chartImages/RadarChar-2.jpg';
import RadarChart from './chartImages/RadarChart.jpg';
import RadarChart3 from './chartImages/RadarChart-3.jpg';
import RadarChart4 from './chartImages/RadarChart-2.jpg';
import RadarColumnChart from './chartImages/RadarColumnChart.jpg';
import ScatterChart from './chartImages/ScatterChart.jpg';
import StackedChart from './chartImages/StackedChart.jpg';
import StackedConnectedChart from './chartImages/StackedConnectedChart.jpg';
import StepChart from './chartImages/StepChart.jpg';
import TimeLineChart from './chartImages/TimeLineChart.jpg';
import VerticalWingChart from './chartImages/VerticalWingChart.jpg';
import WordTreeDiagram from './chartImages/WordTreeDiagram.jpg';




// import veeruResume from './components/Veeru-Resume';

export default function Exercises() {
   const [isColumnChart, setIsColumnChart] = useState(false);
   const [isBarChart, setIsBarChart] = useState(false);
   const [isPieChart, setIsPieChart] = useState(false);
   const [isRadarChart, setIsRadarChart] = useState(false);
   const [isLineChart, setIsLineChart] = useState(false);
   const [isScatterChart, setIsScatterChart] = useState(false);
   const [isOtherChart, setIsOtherChart] = useState(false);

   const hideAll = () => {
        setIsColumnChart(false);
        setIsBarChart(false);
        setIsPieChart(false);
        setIsRadarChart(false);
        setIsLineChart(false);
        setIsScatterChart(false);
        setIsOtherChart(false);
   }
  const handleColumnChart = () => {
        hideAll();
        setIsColumnChart(true);
  };
  const handleBarChart = () => {
        hideAll();
        setIsBarChart(true);
  };
  const handlePieChart = () => {
        hideAll();
        setIsPieChart(true);
  };
  const handleRadarChart = () => {
        hideAll();
        setIsRadarChart(true);
  };
  const handleLineChart = () => {
        hideAll();
        setIsLineChart(true);
  };
  const handleScatterChart = () => {
        hideAll();
        setIsScatterChart(true);
  };
  const handleOtherChart = () => {
        hideAll();
        setIsOtherChart(true);
  };

    return (   
<div>     
        <div className="charts-total-content">
            <div className="charts-left-navigation">
                <h3>Chart Types</h3>
                <a href="#columnchart" onClick={handleColumnChart}>Column Chart</a>
                <a href="#barchart"  onClick={handleBarChart} >Bar Chart</a>
                <a href="#barchart" onClick={handlePieChart}>Pie Chart</a>
                <a href="#barchart" onClick={handleRadarChart}>Radar Chart</a>
                <a href="#barchart" onClick={handleLineChart}>Line Chart</a>
                <a href="#barchart" onClick={handleScatterChart}>Scatter Chart</a>
                <a href="#barchart" onClick={handleOtherChart}>Other Charts</a>
            </div>

            {isBarChart&&(
                <div className="right-navigation">
                <div className="card">
                    <div className="card-image">
                        <img src={BarChart} alt="Bar Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Bar Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3BarChart1} alt="3D Bar Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Bar Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3ConeBarChart} alt="3D Cone Bar Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Cone Bar Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3PyramidBarChart} alt="3D Pyramid Bar Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Pyramid Bar Chart
                    </div>
                </div>
            </div>
            )}

            {isColumnChart&&(
            <div className="right-navigation">    
                <div className="card">
                    <div className="card-image">
                        <img src={D3ColumnChart} alt="3D-Column Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Column Chart
                    </div>
                </div>
                
                <div className="card">
                    <div className="card-image">
                    <img src={ColumnChart} alt="ColumnChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Column Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3ConeChart} alt="3D-Cone Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Cone Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3CylinderChart} alt="3D-Cylinder Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Cylinder Chart
                    </div>
                </div>

            </div>
            )}

            {isPieChart&&(
            <div className="right-navigation">   
                <div className="card">
                    <div className="card-image">
                        <img src={D3PieChart} alt="3D Pie chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Pie Chart
                    </div>
                </div>

                
                <div className="card">
                    <div className="card-image">
                        <img src={D3PyramidChart} alt="3D-Pyramid Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Pyramid Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={D3DoughnutChart} alt="3D Doughnut Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        3D Doughnut Chart
                    </div>
                </div>
            </div>
            )}

            {isOtherChart&&(
            <div className="right-navigation">
                <div className="card">
                    <div className="card-image">
                        <img src={Stacked100Chart} alt="100% Stacked Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        100% Stacked Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                        <img src={Stacked100ConnectedChart} alt="100% Stacked Connected Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        100% Stacked Connected Chart
                    </div>
                </div>


                <div className="card">
                    <div className="card-image">
                    <img src={StackedChart} alt="Stacked Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Stacked Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={StackedConnectedChart} alt="Stacked Connected Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Stacked Connected Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={AreaChart} alt="AreaChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Area Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={BubbbleChart} alt="BubbleChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Bubble Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={DashboardDesign1} alt="ChartDeveloper-DashboardDesign" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Chart Developer Dasshboard
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={ChordDiagram} alt="Chord Diagram" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Chord Diagram
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={CircularColumnChart} alt="Circular Column Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Circular Column Chart
                    </div>
                </div>


                <div className="card">
                    <div className="card-image">
                    <img src={CurveChart} alt="CurveChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Curve Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={DoughnutChart} alt="Doughnut Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Doughnut Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={EquilibriamChart} alt="Equilibrium Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Equilibrium Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={FlowDiagram} alt="FlowDiagram" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Flow Chart
                    </div>
                </div>
                <div className="card">
                    <div className="card-image">
                    <img src={HierarchicalChart} alt="Hierarchical Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Hierarchical Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={HorizontalWingChart} alt="Horizontal Wing Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Horizontal Wing Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={VerticalWingChart} alt="Vertical Wing Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Vertical Wing Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={InvertedPyramidChart} alt="Inverted Pyramid Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Inverted Pyramid Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={Linechart} alt="LineChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Line Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={LollipopChart} alt="Lollipop Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Lollipop Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={PieChart} alt="PieChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        2D Pie Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={PyramidChart} alt="PyramidChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Pyramid Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={RadarChart2} alt="Radar Char-2" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Radar Chart 1
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={RadarChart} alt="Radar Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Radar Chart 2
                    </div>
                </div>


                <div className="card">
                    <div className="card-image">
                    <img src={RadarChart4} alt="RadarChart-2" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Radar Chart 3
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={RadarChart3} alt="Radar Chart-3" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Radar Chart 4
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={RadarColumnChart} alt="RadarColumn Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Radar Column Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={ScatterChart} alt="ScatterChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Scatter Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={StepChart} alt="StepChart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Step Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={TimeLineChart} alt="TimeLine Chart" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Timeline Chart
                    </div>
                </div>

                <div className="card">
                    <div className="card-image">
                    <img src={WordTreeDiagram} alt="WordTree Diagram" ></img>
                    </div>
                    <div className="card-content">
                        lorem ipsum
                    </div>
                    <div className="card-title">
                        Word Tree Diagram
                    </div>
                </div>
            </div>  
            )}
        </div>
    </div>
        )
}