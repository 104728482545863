import React from'react';

function App() {
  return(
    <div className="App">
      <iframe src="HtmlPage1.html" title="react  Method-1" width="100%" height="450"></iframe>
    </div>
  );
}

export default App;