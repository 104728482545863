import './App.css';
import React from "react";
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { Route } from 'react-router-dom';
//import { Routes } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { Switch } from 'react-router-dom';

// import ChartMaker from './ChartMaker';
// import CrossTableMaker from './components/CrossTableMaker';
import FormMaker from './components/FormMaker';
import GridMaker from './components/GridMaker';
import VeeruResume from './VeeruResume';
import MegaMenu from './MegaMenu';


function App() { 
  return (
         <div>
         <Router>
          {/* <h1>React Website</h1> */}
            {/* <nav>
               <ul>
                  <li>
                     <Link to="/About">About</Link>
                  </li>
                  <li>
                     <Link to="/Tools">Tools</Link>
                  </li>
                  <li>
                     <Link to="/UseCases">Use Cases</Link>
                  </li>
                  <li>
                     <Link to="/Contact">Contact Us</Link>
                  </li>
               </ul>
            </nav> */}

          <Switch>
            <Route path="/VeeruResume">
                <VeeruResume />
            </Route>
            
            <Route path="/">
            <h1>React Website</h1>
               <MegaMenu />
            </Route>

            <Route path="/Resume">
                <VeeruResume />
            </Route>

            <Route path="/UseCases">
                <FormMaker />
            </Route>

            <Route path="/Contact">
               {/* <Redirect push to={"/HtmlPage1.html"} /> */}
                <GridMaker />
            </Route>
          </Switch>
      </Router>
      </div>
  );
}

export default App;